import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: '', redirectTo: '/app/tenants', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: 'account/account.module#AccountModule',
        data: { preload: true }
    },
    {
        path: 'admin',
        redirectTo: 'account/login/admin'
    },
    {
        path: 'inspector',
        redirectTo: 'account/login/inspector'
    },
    {
        path: 'report',
        redirectTo: 'account/login/report'
    },
    {
        path: 'app',
        loadChildren: 'app/app.module#AppModule',
        data: { preload: true }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class RootRoutingModule { }
