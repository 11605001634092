import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/nav/app-url.service';

@Injectable()
export class AppAuthService {

    constructor(
        private _appUrlService: AppUrlService
    ) {
    }

    logout(reload?: boolean): void {
        abp.auth.clearToken();
        if (reload !== false) {
            location.href = this._appUrlService.appRootUrl;
        }
    }
}
