export class AppConsts {

  static remoteServiceBaseUrl: string
  static appBaseUrl: string
  static appBaseHref: string // returns angular's base-href parameter value if used during the publish

  // Regular expression wrote according to the specification found on https://en.wikipedia.org/wiki/Tire_code
  static tyreSizeRexExp = /([a-zA-Z]{0,2})(\d{2,3})\/(\d{2,3})([RBD])(\d{1,2}(?:\.\d)?) ?(\d{2,3})?([A-Z]{0,2})?/g
  static localeMappings: any = []

  static readonly userManagement = {
    defaultAdminUserName: 'admin'
  }

  static readonly permissions = {
    Inspection_CanDelete: 'Inspection.CanDelete',
    Inspection_CanUpdateInspectionValue: 'Inspection.CanUpdateInspectionValue',
    Pages_Branches: 'Pages.Branches',
    Pages_Dashboard: 'Pages.Dashboard',
    Pages_Inspection: 'Pages.Inspection',
    Pages_InspectionBuilder: 'Pages.InspectionBuilder',
    Pages_Roles: 'Pages.Roles',
    Pages_Tenants: 'Pages.Tenants',
    Pages_Users: 'Pages.Users',
    Pages_VehicleModels: 'Pages.VehicleModels'
  }

  static readonly localization = {
    defaultLocalizationSourceName: 'VINZ'
  }

  static readonly authorization = {
    encrptedAuthTokenName: 'enc_auth_token'
  }

  static readonly vehicleNumberTypes = {
    REGO: 'registrationNumber',
    VIN: 'vinNumber',
    ID: 'stockNumber'
  }

  static readonly dataTypes = {
    $Datetime: 'datetime',
    $Decimal: 'decimal',
    $Int: 'int',
    $String: 'string',
    Icon: 'Icons',
    ObservationStatus: 'ObservationStatus',
    ParkBreakEfficiency: 'ParkBreakEfficiency',
    Photo: 'Photo',
    SplatDiagramPoint: 'SplatDiagramPoint',
    SplatDiagram: 'SplatDiagram',
    StandardCriteriaIcon: 'StandardCriteriaIcon',
    StructuralDamage: 'StructuralDamage',
    THLCriteriaIcon: 'THLCriteriaIcon',
    TyreSize: 'TyreSize',
    VehicleConditionRating: 'VehicleConditionRating',
    GeneralCondition: 'GeneralCondition',
    WeatherCondition: 'WeatherCondition',
    YesNo: 'YesNo',
    VehicleBodyShape: 'VehicleBodyShape',
    VehicleColor: 'VehicleColor',
    VehicleFuelSystem: 'VehicleFuelSystem',
    VehicleFuelType: 'VehicleFuelType',
    VehicleMake: 'VehicleMake',
    VehicleModel: 'VehicleModel',
    VehicleOrigin: 'VehicleOrigin',
    VehicleTransType: 'VehicleTransType',
    VehicleSeats: 'VehicleSeats',
    VehicleYear: 'VehicleYear',
    VehicleCyclinders: 'VehicleCyclinders',
    DriveType: 'DriveType'
  }

  static readonly inspectionCriteriaCodes = {
    StandardCriteriaCode: 'PPI',
    THLCriteriaCode: 'THL'
  }

  static readonly inpsectionPointCodes = {
    CyclindersIdleBalanceCode: 'D001',
    CylindersCode: 'VD009',
    OdometerCode: 'VD007',
    VehicleBodyShapeCode: 'VD012',
    SubModelCode: 'VD005',
    IsSpareUsableExistCode: 'SP001',
    DoesSpareTyreExistCode: 'SP002',
    SpareCode: 'TD07',
    SecondaryColorCode: 'VD014',
    EngineCCRatingCode: 'VD008',
    NumberOfAxels: 'VD013',
    ParkBrakeEfficiencyCode: 'EF001',
    ParkBrakeStaticCode: 'SB001',
    TestDriveEfficiencyCode: 'EF002',
    TestDriveBrakeStaticCode: 'SB002',
    UnderbodyEfficiencyCode: 'EF003',
    UnderbodyBrakeStaticCode: 'SB003',
    RUCExpiryCode: 'VD015',
    TreadDepthCode: 'TD01',
    TimeToStartCode: 'D002',
    AlternatorTestCode: 'D004',
    PrimaryColourCode: 'VD002',
    SecondaryColourCode: 'VD014'
  }

  static readonly toastrConfiguration = {
    'closeButton': true,
    'closeHtml': '<button><svg class="icon-close"><use xlink:href="#icon-close"></use></svg></button>',
    'closeMethod': 'fadeOut',
    'closeDuration': '300',
    'debug': false,
    'newestOnTop': false,
    'progressBar': false,
    'positionClass': 'toast-bottom-center',
    'preventDuplicates': true,
    'onclick': null,
    'showDuration': '600',
    'hideDuration': '1000',
    'timeOut': '6000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut',
    'tapToDismiss': true,
    'closeOnHover': true
  }
}
